import React from 'react';
import platebgImg from '../images/duck_pc.png';
import { motion } from "framer-motion";
import ProdictionCard from './ProductionCard';
import Modal_hero from './Modal_hero';


const Hero = () => {
  return (
    <>
  {/* // Section on laptop */}
  <div id="hero" className="absolute top-0 left-0"></div>
  <section className="hidden sm:block text-gray-600 body-font bg-bg2 w-full z-30" >
      <div className="container md:px-5 md:mx-auto flex md:bg-bg1 bg-bg2 flex-wrap rounded-xl">
        <div className="md:p-4 md:w-1/2">
          <div className="md:text-left text-center md:pr-12 md:py-48 pt-24 md:items-center"> 
            <h1 className="md:text-9xl text-center text-3xl font-bold text-green-900">
              ПОСТАВКА    
            </h1>
            <div className='flex md:justify-center'>
              <div className='md:w-96 mx-auto w-24 border-b-4 border-green-900 md:my-1'></div>
            </div>
            <p className="leading-relaxed text-center md:text-2xl text-xl font-semibold text-gray-500 mt-2">
              охлажденных полуфабрикатов сетевым магазинам
            </p>
            <div className='flex md:justify-center mt-4'>
                <Modal_hero/>
            </div>
            
          </div>
        </div>
        {/* //Картинка */}
        <div className="md:w-1/2 md:overflow-visible z-30">
          <img alt="plate img" className="md:w-full md:scale-110 md:translate-y-32 md:translate-x-0 z-40 " src={platebgImg} />
        </div>  
      </div>
  </section>
 
  {/* //Mobile version */}
  <section className="md:hidden text-gray-600 body-font bg-bg1 bg-plateImg bg-cover bg-center z-40" id='heroMob'>
    <div className="px-8 text-green-900 md:rounded-3xl flex flex-col h-[80vh] shadow-lg justify-center z-30  bg-bg1/70">
      <div className='flex flex-col md:justify-center w-full'>
        <h1 className="font-semibold text-7xl text-center relative z-30">
          ПОСТАВКА    
        </h1>
      <div className='md:mr-16 md:w-96 mx-auto w-60 border-b-4 border-green-900 md:my-1 z-30'></div>
    </div>
      <h2 className="leading-relaxed text-center text-lg font-semibold text-gray-800 z-30">
        полуфабрикатов сетевым магазинам
      </h2>
      <Modal_hero/>
    </div>
  </section>
  
  <section className="text-gray-600 body-font bg-bg2 relative">
    <div id="products" className="absolute top-32 left-0"></div>
    <div id="productsMobile" className="absolute top-12 left-0"></div>
      {/* //Наша продукция */}
      <div className="md:pt-20 pt-6 bg-bg2 z-20" >
        <h2 className="md:pt-32 pt-20 my-1 text-center text-3xl md:text-6xl text-green-900 font-bold">Наша продукция</h2>
          <div className='flex justify-center'>
            <div className='md:w-96 w-44 border-b-4 border-green-900 md:my-4 my-2'></div>
          </div>
      </div>
      <div>
          <p className="leading-relaxed text-center md:text-2xl text-md font-semibold text-gray-500 md:w-2/3 md:mx-auto mx-2">
            Компания "Фермерском супермаркет" это надёжный партнёр по качественным продуктам из местного производства! Мы гордимся тем, что предлагаем вам только самые свежие полуфабрикаты из мяса прямо с фермы.
          </p>
      </div>
        <div className="flex flex-col md:flex-row justify-center mx-auto items-center gap-4 w-5/6 md:mt-12 mt-6">
          <ProdictionCard/>
        </div>
      </section>
  </>
  )
}

export default Hero;